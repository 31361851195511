/* body,
html {
    height: 100%;
    margin: 0;
    font: 400 15px/1.8 "Lato", sans-serif;
} */

.bgimg-1,
.bgimg-2,
.bgimg-3 {
    position: relative;
    opacity: 0.65;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.bgimg-1 {
    background-image: url("../../assets/images/mt-background.png");
    min-height: 100%;
}

.bgimg-2 {
    background-image: url("../../assets/images/coder2.png");
    min-height: 100%;
}

.bgimg-3 {
    background-image: url("../../assets/images/mt-background-3.png");
    min-height: 400px;
}

.bgimg-4 {
    background-image: url("../../assets/images/mt-background-4.png");
    min-height: 100%;
}

.lato-text {
    letter-spacing: 5px;
    text-transform: uppercase;
    font: "Lato", sans-serif;
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {

    .bgimg-1,
    .bgimg-2,
    .bgimg-3 {
        background-attachment: scroll;
    }
}